// import { getListBooking } from "@/api/booking";
import { defineStore } from "pinia";
import {
  getListAllotment,
  updateAllotment,
  getAllotmentDetail,
  updateAllotmentDetail,
  blockAllotment,
  getListAllotmentSetter,
  createAllotment,
  unBlockAllotment,
  getMergeAllotment,
} from "@/api/allotment";

import { ALLOTMENT_DAYS } from "@/config/allotment.config";

export const useAllotmentStore = defineStore({
  id: "allotment",
  state: () => ({
    bookingDays: ALLOTMENT_DAYS,
    allTimeInDays: [],
    allotmentSetter: null,
    allotsData: {},
    allotments: [],
    // alotmentDetails: null,
    showAllotmentDetails: null,
    allotmentDetails: [],
    zoomable: {
      scale: 1,
      transformOrigin: "center center",
      scrollX: 0,
      scrollY: 0,
    },
  }),
  getters: {
    getBookingDays: (state) => state.bookingDays,
  },
  actions: {
    resetBookingDays() {
      this.bookingDays = ALLOTMENT_DAYS;
    },
    applyFirstDayToAll() {
      const receiveBookingDaysValue = this.bookingDays;
      const firstDaySetting = receiveBookingDaysValue[0];

      const copyFromFirstTime = {
        closed: firstDaySetting.closed,
        firstBookingTime: firstDaySetting.firstBookingTime,
        lastBookingTime: firstDaySetting.lastBookingTime,
        seatAvailable: firstDaySetting.seatAvailable,
      };

      const newValue = [firstDaySetting];
      for (let i = 1; i < this.bookingDays.length; i++) {
        const subTimes = firstDaySetting.subTimes.map((time) => {
          return {
            ...time,
            dayId: this.bookingDays[i].id,
            id: Math.floor(Math.random() * (new Date().getTime() - 0 + 1) + 0),
            label: this.bookingDays[i].label,
          };
        });
        const firstTimeClonse = {
          ...this.bookingDays[i],
          ...copyFromFirstTime,
          subTimes,
        };

        newValue.push(firstTimeClonse);
      }

      this.bookingDays = newValue;
    },
    closeReceiveBookingTime(id, status, subId = null) {
      const receiveBookingDays = this.bookingDays;
      const indexById = receiveBookingDays.findIndex((item) => item.id === id);
      if (!subId) {
        this.bookingDays[indexById].closed = status;
        return;
      }

      const indexBySubId = this.bookingDays[indexById].subTimes.findIndex(
        (item) => item.id === subId,
      );

      if (indexBySubId < 0) return;
      this.bookingDays[indexById].subTimes[indexBySubId].closed = status;
    },
    getListAllotments(payload) {
      return new Promise((resolve, reject) => {
        getListAllotment(payload)
          .then(async (response) => {
            const data = response;
            if (data && typeof data === "object") {
              Object.keys(data).forEach((key) => {
                if (key.match(/^\d{2}:\d{2}$/)) {
                  // Check if the key is in HH:MM format
                  data[key].isOpen = false; // Add the `isOpen` attribute
                }
              });
              this.allotsData = data;
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createAllotment(payload) {
      return new Promise((resolve, reject) => {
        createAllotment(payload)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateAllotment(payload) {
      return new Promise((resolve, reject) => {
        updateAllotment(payload)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            // this.getListBookingDays();
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAllotmentDetail({ id, restaurant_id }) {
      return new Promise((resolve, reject) => {
        getAllotmentDetail({ id, restaurant_id })
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            // this.alotmentDetails = response.data;
            const attributes = {
              id: id,
              restaurant_id: restaurant_id,
              ...response.data.attributes,
            };
            resolve(attributes);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateAllotmentDetail(payload) {
      return new Promise((resolve, reject) => {
        updateAllotmentDetail(payload)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    blockAllotment(payload) {
      return new Promise((resolve, reject) => {
        blockAllotment(payload)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getListAllotmentSetter(payload) {
      return new Promise((resolve, reject) => {
        getListAllotmentSetter(payload)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            this.allotmentSetter = response.data;
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    unBlockAllotment(payload) {
      return new Promise((resolve, reject) => {
        unBlockAllotment(payload)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getMergeAllotment(payload) {
      return new Promise((resolve, reject) => {
        getMergeAllotment(payload)
          .then((response) => {
            if (response?.success === false) {
              reject(response);
              return;
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
